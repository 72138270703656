import styled from 'styled-components';

import type { IPlacePickerTemplate } from '../../themes';

export default styled.aside<{ theme: IPlacePickerTemplate }>`
  background: whitesmoke;
  width: 550px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  ${({ theme }) => theme.position.sidebar}: 0;

  @media (max-width: 992px) {
    width: 350px;
    left: 0;
  }
`;
