import './styles.css';

import { LoadScript } from '@react-google-maps/api';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { globalCache } from '@webstore-monorepo/shared/utils/global-cache';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import React, { useEffect, useMemo, useState } from 'react';
import Loadable from 'react-loadable';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { useQueryLocationsStoresTemplate } from '../../api';
import HeaderLogo from '../../components/HeaderLogo/HeaderLogo';
import { StoreBanner } from '../../components/Map/MapBanner';
import * as s from '../../components/shared';
import { Spinner } from '../../components/Spinner/Spinner';
import { defaultMapDetails } from '../../constants/defaultMapDetails';
import { useLocationsState } from '../../contexts/LocationStoresProvider';
import { useGetFilteredStores } from '../../hooks';
import themes from '../../themes';
import { Logo } from './MainContainer.styled';

const Map = Loadable({
  loader: async () => import('../../components/Map/Map'),
  loading() {
    return <React.Fragment />;
  },
});

const Sidebar = Loadable({
  loader: async () => import('../sidebar/Sidebar'),
  loading() {
    return <React.Fragment />;
  },
});

const libraries: ['geometry', 'drawing', 'places'] = ['geometry', 'drawing', 'places'];

export const MainContainer: React.FC = () => {
  const merchantStore: any = useMerchantState();
  const { isLoading } = useGetFilteredStores(window.location.search);
  const { stores } = useLocationsState();

  const { data: merchantTemplate } = useQueryLocationsStoresTemplate(window.merchantId);

  const [width, setWidth] = useState(window.innerWidth);
  const mapDetails = merchantStore?.placePicker?.mapConfig ?? defaultMapDetails;
  const theme = useMemo(() => merge(themes, merchantTemplate?.template), [merchantTemplate?.template]);

  const handleSizeChange = debounce(() => {
    setWidth(window.innerWidth);
  }, 100);

  let GlobalStyle = null;

  useEffect(() => {
    window.addEventListener('resize', handleSizeChange);

    if (globalCache.locationSelectorAddress) {
      delete globalCache.locationSelectorAddress;
    }

    if (globalCache.locationSelectorIsPickup) {
      delete globalCache.locationSelectorIsPickup;
    }

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  }, []);

  if (merchantTemplate?.template && Array.isArray(merchantTemplate.template.fontSources)) {
    let fontFace = '';
    merchantTemplate.template.fontSources.forEach((font) => {
      fontFace += `@font-face {
        font-family: "${font.name}";
        src: url(${font.src}) format('${font.format}');
        ${font.weight ? 'font-weight:' : ''} ${font.weight ? font.weight : ''};
        ${font.style ? 'font-style:' : ''} ${font.style ? font.style : ''};
      }`;
    });
    GlobalStyle = createGlobalStyle`
      ${fontFace}`;
  }

  if (!merchantStore || !merchantTemplate?.template) {
    return (
      <div className="loading-container">
        {GlobalStyle ? <GlobalStyle /> : null}
        <Spinner isLoading />
      </div>
    );
  }

  if (width < 796) {
    return (
      <ThemeProvider theme={theme}>
        {GlobalStyle ? <GlobalStyle /> : null}
        <s.Body style={{ minHeight: '100vh' }}>
          <s.Header className="header" data-testid="header-mobile">
            <Logo>
              <HeaderLogo headerLogo={theme?.headerLogo} merchantStore={merchantStore} />
            </Logo>
          </s.Header>
          <s.Layout>
            <LoadScript id="loader-script" libraries={libraries} googleMapsApiKey={window.googleApiKey}>
              <div style={{ height: '200px' }}>
                {theme.banner && theme.banner.enabled && theme.banner.enabled.mobile ? <StoreBanner theme={theme} isMobile /> : null}
                {mapDetails && stores.length ? <Map mapDetails={mapDetails} theme={theme} /> : null}
              </div>
              <Sidebar theme={theme} isLoadingStores={isLoading} />
            </LoadScript>
          </s.Layout>
        </s.Body>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {GlobalStyle ? <GlobalStyle /> : null}
      <s.Body style={{ height: '100vh' }}>
        <s.Header className="header" data-testid="header">
          <Logo>
            <HeaderLogo headerLogo={theme?.headerLogo} merchantStore={merchantStore} />
          </Logo>
        </s.Header>
        <s.Layout>
          <LoadScript libraries={libraries} googleMapsApiKey={window.googleApiKey} id="loader-script">
            <s.Sidebar
              id="layout-sider"
              style={{
                background: theme.sidebar.backgroundColor || 'whitesmoke',
                height: `calc(100% - ${theme.header.height})`,
                overflow: 'auto',
                position: 'fixed',
              }}
            >
              <Sidebar theme={theme} isLoadingStores={isLoading} />
            </s.Sidebar>
            <s.Container className="layout-map">
              <div className="content">
                {theme.banner && theme.banner.enabled && theme.banner.enabled.desktop ? <StoreBanner theme={theme} /> : null}
                {mapDetails && stores.length ? <Map mapDetails={mapDetails} theme={theme} /> : null}
              </div>
            </s.Container>
          </LoadScript>
        </s.Layout>
      </s.Body>
    </ThemeProvider>
  );
};
