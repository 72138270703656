import styled from 'styled-components';

import type { IPlacePickerTemplate } from '../../themes';

export default styled.section<{ theme: IPlacePickerTemplate }>`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: ${({ theme }) => theme.fontSize.primary};
  background-color: ${({ theme }) => theme.bg.main};
  display: flex;
  flex-direction: column;
`;
