import styled from 'styled-components';

import type { IPlacePickerTemplate } from '../../themes';

export default styled.section<{ theme: IPlacePickerTemplate }>`
  background: #f0f2f5;
  display: flex;
  flex-direction: column;
  flex: auto;
`;
