import styled from 'styled-components';

import type { IPlacePickerTemplate } from '../../themes';

export default styled.section<{ theme: IPlacePickerTemplate }>`
    padding: 0;

    background: whitesmoke;
    margin-${({ theme }) => theme.position.sidebar}: 550px;
    @media (max-width: 992px) {
        margin-${({ theme }) => theme.position.sidebar}: 350px;
    }
`;
