import styled from 'styled-components';

import type { IPlacePickerTemplate } from '../../themes';

export const Logo = styled.a<{ theme: IPlacePickerTemplate }>`
  color: #1890ff;

  &:focus {
    outline: ${({ theme }) => theme.color.secondary} auto 1px;
  }

  img {
    vertical-align: middle;
  }
`;
