import './styles.css';

import React from 'react';

import { LoadingIcon } from '../../../components/uielements/icons/LoadingIcon';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  pastDelay?: string | React.ReactNode;
  timedOut?: boolean;
}

export const Spinner: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      {props.error ? 'Error! Please refresh the page' : ''}
      {props.isLoading ? <LoadingIcon className="spinner" width={14} height={14} /> : ''}
      {props.pastDelay}
      {props.timedOut ? 'Taking a long time...' : ''}
    </React.Fragment>
  );
};
